export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export function portfolioComparator(a, b) {
    if (a.create_date > b.create_date) {
        return -1
    }
    if (a.create_date < b.create_date) {
        return 1
    }
    return 0
}
