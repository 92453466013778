<template>
    <div class="portfolio-item">
        <b-card
            class="portfolio-card"
            :title="projectData.name"
            :img-src="getImageSrc"
            img-top
            @click="$emit('open-viewer', projectData)"
        >

            <b-card-text>{{projectData.comment}}</b-card-text>
        </b-card>
    </div>
</template>
<script>


export default {
    props: {
        projectData: Object,
    },
    computed: {
        getImageSrc() {
            if (this.projectData.portfolio_img_s3_path) {
                return this.projectData.portfolio_img_s3_path
            }
            return require('@/assets/images/3d.jpg')
        },
    },
    methods: {
        oepnV() {
            window.open(this.projectData.production_url);
        },
    },
}
</script>
<style  lang="scss" scoped>
    .portfolio-item {
        display: flex;
        justify-content: center;
        width: 30%;
        min-width: 200px;
        margin-top: 1em;
        .portfolio-card {
            cursor: pointer;
            width: 100%;

            .card-img-top {
                width: 100%;
                height: auto;
                max-height: 25em;
                object-fit: cover;
            }
        }
    }
</style>
