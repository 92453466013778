<template>
    <div class="viewer-main">
        <div class="viewer">
            <iframe
                class="viewer-iframe"
                :src="projectData.production_url"
                frameborder="0"
                allowfullscreen
            ></iframe>
        </div>
        <div class="buttons">
            <b-button variant="outline-danger" @click="$emit('close-viewer')"><feather-icon icon="XIcon" size="20" /></b-button>
            <b-button variant="outline-success" @click="openInNewTab"><feather-icon icon="ExternalLinkIcon" size="20" /></b-button>
        </div>
        
    </div>
</template>
<script>


export default {
    props: {
        projectData: Object,
    },
    methods: {
        openInNewTab() {
            window.open(this.projectData.production_url, '_blank')
        },
    },
}
</script>
<style  lang="scss" scoped>
    .viewer-main {
        position: fixed;
        display: flex;
        flex-direction: row;
        z-index: 2;
        width: 100%;
        height: 100%;

        .viewer {
            width: 90%;
            height: 100%;

            .viewer-iframe {
                width: 100%;
                height: 100%;
            }
        }
        .buttons {
            display: flex;
            flex-direction: column;
            gap: 50px;
            align-items: center;
            justify-content: center;
            width: 10%;
            height: 100%;
        }
    }

    @media screen and (max-width: 800px){
        .viewer-main {
            flex-direction: column;
            
            .viewer {
                width: 100%;
                height: 90%;
            }
            .buttons {
                flex-direction: row;
                width: 100%;
                height: 10%;
            }
        }
    }
</style>
