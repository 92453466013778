<template>
  <div class="footer">
    <div class="footer-container">
      <div class="footer-columns">
        <div class="footer-column first">
          <img :src="require('@/assets/images/3Digital_full_logo_gray.png')" style="max-width:100px;object-fit:contain;height:40px" />
          <span class="footer-span">{{ $t("footer1") }}</span>
          <img :src="require('@/assets/images/swiss-made-software.png')" style="max-width:273px;object-fit:contain;height:60px" />
        </div>
        <div class="footer-column">
          <p class="footer-p strong-p">
            <strong>{{ $t("footer2") }}</strong>
          </p>
          <router-link @click.native="$scrollToTop" to="solution"
            ><p class="footer-p">{{ $t("footer3") }}</p></router-link
          >
          <router-link @click.native="$scrollToTop" to="portfolio"
            ><p class="footer-p">{{ $t("footer4") }}</p></router-link
          >
        </div>
        <div class="footer-column">
          <p class="footer-p strong-p">
            <strong>{{ $t("footer5") }}</strong>
          </p>
          <router-link @click.native="$scrollToTop" to="platform"
            ><p class="footer-p">{{ $t("footer6") }}</p></router-link
          >
          <router-link @click.native="$scrollToTop" to="pricing"
            ><p class="footer-p">{{ $t("footer7") }}</p></router-link
          >
          <router-link @click.native="$scrollToTop" to="release-notes"
            ><p class="footer-p">{{ $t("footer8") }}</p></router-link
          >
        </div>
        <div class="footer-column">
          <p class="footer-p strong-p">
            <strong>{{ $t("footer9") }}</strong>
          </p>
          <router-link @click.native="$scrollToTop" to="contact-us"
            ><p class="footer-p">{{ $t("footer10") }}</p></router-link
          >
          <router-link @click.native="$scrollToTop" to="about-us"
            ><p class="footer-p">{{ $t("footer11") }}</p></router-link
          >
          <router-link @click.native="$scrollToTop" to="blog"
            ><p class="footer-p">{{ $t("footer12") }}</p></router-link
          >
        </div>
        <div class="footer-column about-us">
          <p class="footer-p strong-p">
            <strong>{{ $t("footer13") }}</strong>
          </p>
          <p class="footer-p">
            {{ $t("footer14") }}
          </p>
        </div>
        <div class="footer-column contact-us">
          <p class="footer-p strong-p">
            <strong>{{ $t("footer15") }}</strong>
          </p>
          <div class="footer-row">
            <a href="tel:+41775073767">
              <feather-icon icon="PhoneIcon" size=25 class="footer-icon" />
              +41 77 507 37 67
            </a>
          </div>
          <div class="footer-row">
            <a href="mailto:3digital@3dch.ch">
              <feather-icon icon="MailIcon" size=25 class="footer-icon" />
              3digital@3dch.h
            </a>
          </div>
          <div class="footer-row">
            <a href="https://www.facebook.com/Team3digital">
              <feather-icon icon="FacebookIcon" size=25 class="footer-icon" />
              Team3digital
            </a>
          </div>
          <div class="footer-row">
            <a href="https://www.instagram.com/3digitalteam/">
              <feather-icon icon="InstagramIcon" size=25 class="footer-icon" />
              3digitalteam
            </a>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="footer-columns bottom">
        <div class="footer-column-bottom terms-policy">
          <router-link @click.native="$scrollToTop" to="privacy">{{
            $t("footer16")
          }}</router-link>
          <router-link @click.native="$scrollToTop" to="terms-of-use">{{
            $t("footer17")
          }}</router-link>
          <router-link
            @click.native="$scrollToTop"
            to="cookies-policy"
            class="last"
            >{{ $t("footer18") }}</router-link
          >
        </div>
        <div class="footer-column-bottom links">
          <a href="https://www.facebook.com/3digital-107323858672603">
            <feather-icon icon="FacebookIcon" size=25 class="footer-icon" />
          </a>
          <a href="https://www.linkedin.com/company/3digitalsarl">
            <feather-icon icon="LinkedinIcon" size=25 class="footer-icon" />
          </a>
          <a href="mailto:3digital@3dch.ch">
            <feather-icon icon="MailIcon" size=25 class="footer-icon" />
          </a>
          <a href="https://www.instagram.com/3dch_3digital/">
            <feather-icon icon="InstagramIcon" size=25 class="footer-icon" />
          </a>
        </div>
        <div class="footer-column-bottom">
          <p class="footer-p">{{ $t("footer19") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  background-color: #0a0a0a;
  width: 100%;
  justify-content: center;
  padding: 40px 20px;
}
.footer-container {
  width: 80%;
  .line {
    border-bottom: 1px solid #fff;
    margin: 0 0 20px 0;
  }
  .footer-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &.bottom {
      justify-content: space-between;
    }
    .footer-column {
      display: flex;
      flex-direction: column;
      width: 230px;
      margin-bottom: 20px;
      margin-right: 10px;
      .footer-row {
        display: flex;
        margin: 5px 0;
        a {
          color: #fff;
          border-bottom: 1px solid transparent;
        }
        a:hover {
          border-bottom: 1px solid #fff;
        }
      }
      .footer-span {
        color: #fff;
        font-size: 13px;
      }
      .footer-p {
        color: #fff;
        margin-bottom: 6px;
        &.strong-p {
          margin-bottom: 10px;
        }
        strong {
          font-size: large;
        }
      }
      a {
        text-decoration: none;
      }
    }
    .footer-column-bottom {
      display: flex;
      flex-wrap: wrap;
      &.terms-policy {
        align-content: center;
        a {
          color: #8e8787;
          border-right: 1px solid #8e8787;
          text-decoration: none;
          padding-right: 20px;
          margin-right: 20px;
          &.last {
            border-right: none;
            margin-right: 0;
            padding-right: 0;
          }
          &:hover {
            color: #fff;
          }
        }
      }
      &.links {
        a {
          text-decoration: none;
        }
      }
      .footer-p {
        color: #fff;
      }
    }
  }
}

.footer-icon {
  background-color: #aa2831;
  color: #fff;
  border-radius: 50px;
  padding: 5px;
  margin-right: 5px;
}

@media screen and (max-width: 639px) {
  .footer-columns {
    justify-content: center !important;
    p,
    span {
      text-align: center;
    }
  }
  .footer-column {
    align-items: center;
  }
  .footer-column-bottom {
    margin-bottom: 10px;
    &.links {
      display: none !important;
    }
  }
}

@media screen and (max-width: 455px) {
  .footer-column-bottom a {
    padding-right: 5px !important;
    margin-right: 5px !important;
  }
  .footer {
    padding: 20px 0;
  }
}

@media screen and (max-width: 300px) {
  .footer-columns {
    justify-content: left;
  }
  .footer-column {
    align-items: left;
  }
  .footer-column-bottom {
    a {
      font-size: 10px;
    }
  }
}

@media screen and (min-width: 1240px) {
  .footer-columns {
    justify-content: flex-start !important;
    &.bottom {
      justify-content: space-between !important;
    }
  }
  .footer-column {
    margin-left: auto;
    &.first,
    &.contact-us,
    &.about-us {
      margin-left: 0;
    }
  }
}
@media screen and (min-width: 1540px) {
  .footer-columns {
    justify-content: flex-start !important;
  }
  .footer-column {
    &.first,
    &.contact-us {
      margin-left: 0;
    }
    &.about-us {
      margin-left: auto;
    }
  }
}
@media screen and (min-width: 1840px) {
  .footer-columns {
    justify-content: flex-start !important;
  }
  .footer-column {
    margin-left: auto !important;
  }
}
</style>
