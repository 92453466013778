<template>
  <div class="d-flex justify-end header-container" :class="{ 'header-container--hidden': !showNavbar }">
      <router-link class="d-none d-sm-block" @click.native="$scrollToTop" to="/">
        <img :src="require('@/assets/images/3Digital_full_logo.png')" class="logo-full" />
      </router-link>
      
    <!-- Mobile Navigation -->
    <router-link class="d-sm-none" @click.native="$scrollToTop" to="/">
      <img
        :src="require('@/assets/images/3Digital_logo.png')"
        class="logo"
      />
    </router-link>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showNavbar: true,
      lastScrollPosition: 0
    }
  },
  methods: {
    onScroll () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition
      this.lastScrollPosition = currentScrollPosition
    },
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  }
};
</script>

<style lang="scss" scoped>
  .header-container {
    position: fixed;
    z-index: 1;
    background-color: white;
    width: 100%;
    padding: 15px;
    transform: translate3d(0, 0, 0);
    transition: 0.1s all ease-out;

    .logo-full {
      min-height: 50px;
      max-width: 200px;
      height: auto;
    }
    .logo {
      min-height: 50px;
      max-width: 100px;
      height: auto;
    }
  }
  .header-container--hidden {
    transform: translate3d(0, -100%, 0);
  }
</style>
