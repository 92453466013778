<template>
<div class="portfolio-main">
    <navbar-component />
    <h2 class="portfolio-title">Portfolio</h2>
    <div
        class="user-information"
        v-if="userData"
    >
        <img class="user-img-left" :src="getProfilePicSrc">
        <div class="user-about">
            <p>Here are represented projects of user {{userData.first_name}} {{userData.last_name}}</p>
            <p v-if="userData.company_name">Company name - {{userData.company_name}}</p>
            <p v-if="userData.description">About me: {{userData.description}}</p>
        </div>
    </div>
    <h2 class="projects-title">Projects</h2>
    <div class="portfolio-list">
        <porfolio-card-vue
            v-for="project in portfolioProjects"
            :projectData="project"
            :key="project.id"
            v-on:open-viewer="openViewer"
        />
    </div>
    <div class="blank-page" v-if="isView" @click="closeViewer"></div>
    <portfolio-viewer v-if="isView" :projectData="viewerProjectData" v-on:close-viewer="closeViewer" />
    <footer-component class="mt-4" />
</div>
</template>
<script>
import BCardCode from "@core/components/b-card-code"
import PorfolioCardVue from './PorfolioCard.vue'
import PortfolioViewer from './PorfolioViewer.vue'
import { portfolioComparator } from '@/utils/utils.js'
import NavbarComponent from "./PortfolioNavbar.vue"
import FooterComponent from "./PortfolioFooter.vue"


export default {
    components: {
        BCardCode,
        PorfolioCardVue,
        PortfolioViewer,
        FooterComponent,
        NavbarComponent,
    },
    data() {
        return {
            userData: null,
            isView: false,
            viewerProjectData: null,
            isScrollBtnHidden: false,
        }
    },
    mounted() {
        this.$store.dispatch('Projects/GET_PUBLIC_PROJECTS', this.$route.params.userID)
        this.$store.dispatch('Profile/GET_USER_PROFILE', this.$route.params.userID).then((res) => {
            this.userData = res.data
        })
    },
    computed: {
        portfolioProjects() {
            return this.$store.getters['Projects/getPortfolioProjecs'].sort(portfolioComparator)
        },
        getProfilePicSrc() {
            if (this.userData.profile_picture) {
                return this.userData.profile_picture
            }
            return require('@/assets/images/profile.png')
        },
    },
    methods: {
        openViewer(data) {
            this.viewerProjectData = data
            this.isView = true
            document.body.style.overflow = 'hidden'

            this.hideScrollBtn()
        },
        closeViewer() {
            this.isView = false
            document.body.style.overflow = 'scroll'

            this.showScrollBtn()
        },
        hideScrollBtn() {
            let btn = document.getElementsByClassName('btn-scroll-to-top show')[0]
            if (btn) {
                btn.classList.remove('show')
                this.isScrollBtnHidden = true
            }
            
        },
        showScrollBtn() {
            if (this.isScrollBtnHidden) {
                let btn = document.getElementsByClassName('btn-scroll-to-top')[0]
                btn.classList.add('show')
                this.isScrollBtnHidden = false
            }
        },
    },
}
</script>
<style  lang="scss" scoped>
    .portfolio-main {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;

        h2.portfolio-title {
            margin-top: 5em;
        }
        .user-information {
            display: flex;
            justify-self: center;
            align-items: center;
            align-content: center;
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 2vw;
            width: 70%;
            margin-bottom: 2em;
            margin-top: 3em;
            background-color: white;

            .user-img-left {
                height: 150px;
                width: 150px;
                margin: 30px 0px 30px 0px;
            }
            .user-about {
                width: 70%;
            }
        }
        h2.projects-title {
            align-self: flex-start;
            margin-left: 15%;
            margin-bottom: 1em;
            margin-top: 2em;
        }
        .portfolio-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 70%;
            gap: 2vw;
            background-color: rgb(255, 255, 255);
        }
    }
    @media screen and (max-width: 1200px){
        .portfolio-main {

            .portfolio-list {
                width: 95%;
            }
        }
        
    }
    .blank-page {
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.689);
    }
</style>
